import React, { useEffect, useState } from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from 'react-icons/fi';
import Slider from 'react-slick';
import { getCareerPage, getJobs } from '../../../util/api.service';
import { slickDot } from '../../../page-demo/script';
import { getImageUrl, handleSpecialPages } from '../../../util/util.service';
import { FormattedDate } from 'react-intl';
import Markdown from 'markdown-to-jsx/dist/index';
import { Link, useHistory } from 'react-router-dom';
import Footer from '../../common/footer/footer.component';
import PageHelmet from '../../common/page-helmet/page-helmet.component';
import Breadcrumb from '../../common/breadcrumb/breadcrumb.component';
import Skeleton from 'react-loading-skeleton';
import Header from '../../common/header/header.component';

const Career = () => {
  const [career, setCareer] = useState(null);
  const [jobs, setJobs] = useState(null);
  const history = useHistory();

  useEffect(() => {
    getCareerPage(history).then(({ attributes }) => setCareer(attributes));
    getJobs().then((jobs) => setJobs(jobs.map(({ attributes }) => attributes)));
  }, []);

  return (
    <>
      <PageHelmet pageTitle={career?.pageTitle}
                  pageDescription={career?.pageSubTitle}/>
      <Header/>
      <Breadcrumb title={career?.pageTitle}
                  image={career?.coverImage}
                  subTitle={career?.pageSubTitle}
      />

      {/* Start Portfolio Area */}
      <div className="portfolio-area pt--90 pb--140 bg_color--1">
        <div className="rn-slick-dot">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div
                  className="section-title text-center service-style--3 mb--30">
                  <h2 className="title">{career?.sliderTitle ||
                    <Skeleton/>}</h2>
                  <p>{career?.sliderSubTitle || <Skeleton/>}</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="slick-space-gutter--15 slickdot--20">
                  <Slider {...slickDot}>
                    {jobs?.map((job, index) => (
                      <div className="portfolio" key={index}>
                        <div className="thumbnail-inner">
                          <div className="thumbnail"
                               style={job?.coverImage && {
                                 backgroundImage: 'url(' +
                                   (getImageUrl(job.coverImage, 'medium') ||
                                     '') + ')',
                               }}/>
                          <div className="bg-blr-image"
                               style={job?.coverImage && {
                                 backgroundImage: 'url(' +
                                   (getImageUrl(job.coverImage, 'medium') ||
                                     '') + ')',
                               }}/>
                        </div>
                        <div className="content">
                          <div className="inner">
                            <p>Starting <FormattedDate value={job.startAt}/>
                            </p>
                            <h4><Link
                              to={'/career/' + job?.slug}>{job?.title}</Link>
                            </h4>
                            <div className="portfolio-button">
                              <Link to={'/career/' + job?.slug}>
                                <a className="rn-btn">More</a>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-lg-12 text-center">
                {career?.bottomContent ?
                  <Markdown>{career.bottomContent}</Markdown> :
                  career?.bottomContent === null ? '' : <p><Skeleton
                    width="100%" count={6}/></p>}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Portfolio Area */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp/>
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      <Footer/>
    </>
  );
};

export default Career;