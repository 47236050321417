import React from 'react';
import PageHelmet from '../../common/page-helmet/page-helmet.component';
import Header from '../../common/header/header.component';
import { Link } from 'react-router-dom';
import Footer from '../../common/footer/footer.component';

const NotFound404 = () => {
  const title = '404 Page not found';

  return (
    <>
      <PageHelmet pageTitle={title}/>
      <Header/>

      <div className="error-page-inner">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner">
                <h1 className="title theme-gradient">404</h1>
                <h3 className="sub-title text-body">Page not found</h3>
                <span className="text-body">The page you were looking for could not be found.</span>
                <div className="error-button">
                  <Link to="/" className="rn-button-style--2 btn-solid">Back To Homepage</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer/>
    </>
  );
};

export default NotFound404;
