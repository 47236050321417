import { API_BASE_URL } from './api.service';

export const isDevEnv = process.env.REACT_APP_APP_ENV !== 'production';

// size is optional but can be large, small, medium or thumbnail
// it's not guaranteed that all of the above sizes exist!!
export const getImageUrl = (image, size, useFallback = true) => {
  if (image?.data) {
    const attributes = image.data.attributes;
    const url = (attributes?.formats && attributes.formats[size]?.url) ||
      attributes?.url; // get requested image size or fallback to original size

    return (url?.startsWith('http') ? '' : API_BASE_URL) + url;
  } else if (useFallback) {
    return '/assets/images/default.jpg';
  } else {
    return null;
  }
};