import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { isDevEnv } from '../../../util/util.service';

gsap.registerPlugin(ScrollTrigger);

const ParallaxGradientHero = ({ title, subTitle }) => {
  const containerRef = useRef(null);
  const contentRef = useRef(null);
  const titleRef = useRef(null);
  const subTitleRef = useRef(null);
  const isDebugMode = isDevEnv;

  useEffect(() => {
    if (containerRef.current && titleRef.current && subTitleRef.current) {
      const gsapContext = gsap.context(() => {
        const showTitle = () => {
          contentRef.current.hidden = false;
          gsap.set(titleRef.current, { autoAlpha: 1 });
        }

        const hideTitle = () => {
          contentRef.current.hidden = true;
          gsap.set(titleRef.current, { autoAlpha: 0 });
        }

        hideTitle(); // set default hidden
        ScrollTrigger.create({
          trigger: containerRef.current,
          start: "top center",
          end: "bottom center",
          onEnter: showTitle,
          onEnterBack: showTitle,
          onLeave: hideTitle,
          onLeaveBack: hideTitle,
        })

        gsap.set(subTitleRef.current, { autoAlpha: 0 }); // set default hidden
        gsap.timeline({
          scrollTrigger: {
            trigger: containerRef.current,
            scrub: true,
            markers: isDebugMode,
            start: 'center center+=50%',
            end: 'bottom bottom',
          },
        }).
          to(subTitleRef.current, { autoAlpha: 1 }).
          to(subTitleRef.current, { autoAlpha: 0 });
      }, containerRef);

      return () => gsapContext.revert(); // cleanup on destroy
    }
  }, []);

  return (
    <div ref={containerRef} className="parallax-gradient-hero-container">
      <div  ref={contentRef} className="content">
        <h1 ref={titleRef} className="title">{title}</h1>
        <h3 ref={subTitleRef} className="sub-title">{subTitle}</h3>
      </div>
      <div className="upper"/>
      <div className="lower"/>
    </div>
  );
};

export default ParallaxGradientHero;