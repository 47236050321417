import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import LazyImage
  from '../../../util/lazyloaders/lazy-image/lazy-image.component';
import { createMessage } from '../../../util/api.service';

const ContactSection = ({ title, description, image }) => {
  const [form, setForm] = useState({ name: '', email: '', message: '' });

  const handleSubmit = () => {
    // check for non-empty fields
    const checkFormIsValid = (fields) => fields.map((id) => {
      const field = document.getElementById('form-' + id);
      if (field?.value) {
        return true;
      } else {
        field.classList.add('border-danger')
        return false;
      }
    }).reduce((prev, curr) => prev && curr, true);

    const fieldIds = ['name', 'email', 'message'];

    if (checkFormIsValid(fieldIds)) createMessage(form).then(() => {
      setForm({ name: '', email: '', message: '' });
    }).catch((err) => {
      const fieldIds = err?.response?.data?.error?.details?.errors?.flatMap(({path}) => path);
      if (fieldIds) checkFormIsValid(fieldIds);
    })
  };

  return (
    <div className="contact-form--1">
      <div className="container">
        <div className="row row--35 align-items-start">
          <div className="col-lg-6 order-2 order-lg-1">
            <div className="section-title text-left mb--50">
              <h2 className="title">{title || <Skeleton/>}</h2>
              <p className="description">{description
                ? description
                : description === null ? '' : <Skeleton/>}</p>
            </div>
            <div className="form-wrapper">
              <label htmlFor="name">
                <input
                  type="text"
                  name="name"
                  id="form-name"
                  value={form?.name}
                  onChange={(e) => {
                    e.target.classList.remove('border-danger');
                    setForm({ ...form, name: e.target.value });
                  }}
                  placeholder="Your Name *"
                />
              </label>

              <label htmlFor="email">
                <input
                  type="text"
                  name="email"
                  id="form-email"
                  value={form?.email}
                  onChange={(e) => {
                    e.target.classList.remove('border-danger');
                    setForm({ ...form, email: e.target.value });
                  }}
                  placeholder="Your E-Mail *"
                />
              </label>

              <label htmlFor="message">
                  <textarea
                    id="form-message"
                    name="message"
                    value={form?.message}
                    onChange={(e) => {
                      e.target.classList.remove('border-danger');
                      setForm({ ...form, message: e.target.value });
                    }}
                    placeholder="Your Message *"
                  />
              </label>

              <button onClick={handleSubmit}
                      className="rn-button-style--2 btn-solid">Submit
              </button>
            </div>
          </div>
          <div className="col-lg-6 order-1 order-lg-2">
            <div className="thumbnail mb_md--30 mb_sm--30">
              <LazyImage
                placeholderWidth={500}
                placeholderHeight={500}
                image={image}
                size="medium"
                alt="Contact Form Image"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactSection;