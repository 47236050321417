// React Required
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

// Create Import File
import './index.scss';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { IntlProviderWrapper } from './context/intl.context';
import Career from './@niocycle/pages/career/career.component';
import CareerDetails
  from './@niocycle/pages/career-details/career-details.component';
import Press from './@niocycle/pages/press/press.component';
import PressDetails
  from './@niocycle/pages/press-details/press-details.component';
  import Blog from './@niocycle/pages/blog/blog.component';
import BlogPosts
  from './@niocycle/pages/blog-posts/blog-posts.component';
import AboutUs from './@niocycle/pages/about-us/about-us.component';
import Contact from './@niocycle/pages/contact/contact.component';
import Home from './@niocycle/pages/home/home.component';
import ScrollToTop from './component/PageScrollTop';
import AppLoader from './@niocycle/common/app-loader/app-loader.component';
import Imprint from './@niocycle/pages/imprint/imprint.component';
import PrivacyPolicy
  from './@niocycle/pages/privacy-policy/privacy-policy.component';
import NotFound404
  from './@niocycle/pages/not-found-404/not-found-404.component';
import InternalServerError500
  from './@niocycle/pages/internal-server-error-500/internal-server-error-500.component';


const Root = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handler = () => {
      console.log('All assets are loaded');
      // wait 0,5 seconds to avoid seeing the font swap
      setTimeout(() => {
        window.scrollTo(0, 0);
        setIsLoading(false);
      }, 500);
    };

    window.addEventListener('load', handler);

    return () => document.removeEventListener('load', handler);
  }, []);

  return (
    <>
      <AppLoader isHidden={!isLoading}/>
      <IntlProviderWrapper>
        <BrowserRouter basename={'/'}>
          <ScrollToTop/>
          <Switch>
            <Route exact path={'/'} component={Home}/>
            <Route exact path={'/career'} component={Career}/>
            <Route exact path={'/career/:slug'} component={CareerDetails}/>
            <Route exact path={'/press'} component={Press}/>
            <Route exact path={'/press/:slug'} component={PressDetails}/>
            <Route exact path={'/blog'} component={Blog}/>
            <Route exact path={'/blog/:slug'} component={BlogPosts}/>
            <Route exact path={'/about-us'} component={AboutUs}/>
            <Route exact path={'/contact'} component={Contact}/>
            <Route exact path={'/imprint'} component={Imprint}/>
            <Route exact path={'/privacy-policy'} component={PrivacyPolicy}/>
            <Route path={`/404`} component={NotFound404} status={404}/>
            <Route path={`/500`} component={InternalServerError500} status={500}/>
            <Route component={NotFound404} status={404}/>

            {/*<Route exact path={`/main-demo`}
                   component={MainDemo}/>
            <Route exact path={`/dark-main-demo`}
                   component={DarkMainDemo}/>
            <Route exact path={`/startup`}
                   component={Startup}/>
            <Route exact path={`/paralax`}
                   component={Paralax}/>

            <Route exact path={`/digital-agency`}
                   component={DigitalAgency}/>
            <Route exact
                   path={`/creative-agency`}
                   component={CreativeAgency}/>
            <Route exact
                   path={`/personal-portfolio`}
                   component={PersonalPortfolio}/>
            <Route exact path={`/studio-agency`}
                   component={StudioAgency}/>
            <Route exact path={`/business`}
                   component={Business}/>
            <Route exact path={`/portfolio-home`}
                   component={HomePortfolio}/>
            <Route exact
                   path={`/portfolio-landing`}
                   component={PortfolioLanding}/>
            <Route exact
                   path={`/creative-landing`}
                   component={CreativeLanding}/>
            <Route exact path={`/home-particles`}
                   component={HomeParticles}/>
            <Route exact
                   path={`/dark-portfolio-landing`}
                   component={DarkPortfolioLanding}/>
            <Route exact
                   path={`/designer-portfolio`}
                   component={DesignerPortfolio}/>
            <Route exact
                   path={`/creative-portfolio`}
                   component={CreativePortfolio}/>
            <Route exact
                   path={`/interior-landing`}
                   component={InteriorLanding}/>
            <Route exact
                   path={`/corporate-business`}
                   component={CorporateBusiness}/>
            <Route exact
                   path={`/interactive-agency`}
                   component={InteractiveAgency}/>

             Element Layot
            <Route exact path={`/service`}
                   component={Service}/>
            <Route exact
                   path={`/service-details`}
                   component={ServiceDetails}/>
            <Route exact path={`/about`}
                   component={About}/>
            <Route exact
                   path={`/portfolio-details`}
                   component={PortfolioDetails}/>
            <Route exact path={`/blog`}
                   component={Blog}/>
            <Route exact path={`/blog-details`}
                   component={BlogDetails}/>

             Blocks Elements
            <Route exact path={`/team`}
                   component={Team}/>
            <Route exact path={`/counters`}
                   component={Counters}/>
            <Route exact path={`/testimonial`}
                   component={Testimonial}/>
            <Route exact path={`/portfolio`}
                   component={Portfolio}/>
            <Route exact path={`/video-popup`}
                   component={VideoPopup}/>
            <Route exact path={`/gallery`}
                   component={Gallery}/>
            <Route exact path={`/clint-logo`}
                   component={Brand}/>
            <Route exact path={`/progressbar`}
                   component={ProgressBar}/>
            <Route exact path={`/contact-form`}
                   component={ContactForm}/>
            <Route exact path={`/google-map`}
                   component={GoogleMap}/>
            <Route exact path={`/columns`}
                   component={Columns}/>
            <Route exact path={`/pricing-table`}
                   component={PricingTable}/>*/}
          </Switch>
        </BrowserRouter>
      </IntlProviderWrapper>
    </>
  );
};

ReactDOM.render(<Root/>, document.getElementById('root'));
/*serviceWorker.register();*/
