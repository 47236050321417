import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { isDevEnv } from '../../../util/util.service';

gsap.registerPlugin(ScrollTrigger);

const GradientMaskedTextHero = ({ text }) => {
  const containerRef = useRef(null);
  const gradientRef = useRef(null);
  const isDebugMode = isDevEnv;

  useEffect(() => {
    if (containerRef.current && gradientRef.current) {
      const gsapContext = gsap.context(() => {
        gsap.set(gradientRef.current, { yPercent: 50 }); // set default
        gsap.to(gradientRef.current, {
          yPercent: -50,
          scrollTrigger: {
            trigger: containerRef.current,
            pin: containerRef.current,
            scrub: true,
            markers: isDebugMode,
            start: 'top top',
            end: '+=200%',
          },
        });
      }, containerRef);

      return () => gsapContext.revert(); // cleanup on destroy
    }
  }, []);

  return (
    <div ref={containerRef} className="gradient-masked-text-hero-container">
      <div className="content">
        <div className="container">
          <h2 className="text">{text}
            <div className="gradient">
              <img ref={gradientRef}
                   src="/assets/images/gradients/gradient-4.jpg"/>
            </div>
          </h2>
        </div>
      </div>
    </div>
  );
};

export default GradientMaskedTextHero;