import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { getImageUrl, isDevEnv } from '../../../util/util.service';
import { slickDot } from '../../../page-demo/script';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

gsap.registerPlugin(ScrollTrigger);

const InfoCardsHero = ({ infoCards }) => {
  const containerRef = useRef(null);
  const isDebugMode = isDevEnv;

  useEffect(() => {
    if (containerRef.current) {
      const gsapContext = gsap.context(() => {
        const infoCards = document.querySelectorAll(
          '.info-cards-hero-container .slick-slide');

        gsap.timeline({
          scrollTrigger: {
            markers: isDebugMode,
            scrub: 1,
            trigger: containerRef.current,
            start: 'top bottom-=50%',
            end: 'center center',
            ease: 'linear',
          },
        }).to(infoCards, { autoAlpha: 1, stagger: 0.3 });
        gsap.set(infoCards, { autoAlpha: 0 }); // set default
      }, containerRef);

      return () => gsapContext.revert(); // cleanup on destroy
    }
  }, []);

  return (
    <div ref={containerRef} className="info-cards-hero-container">
      <div
        className="slide slide-style-1 d-flex align-items-center">
        <div className="container position-relative">
          <h1 className="theme-gradient text-center w-100 mb-4">CHECK OUT THE LATEST
            UPDATES:</h1>
          <div className="slick-space-gutter--15 slickdot--20">
            <Slider {...slickDot}>
              {infoCards?.map((infoCard, index) => (
                <div className="portfolio" key={index}>
                  <div className="thumbnail-inner">
                    <div className="thumbnail"
                         style={infoCard?.image && {
                           backgroundImage: 'url(' +
                             (getImageUrl(infoCard.image, 'medium') ||
                               '') + ')',
                         }}/>
                    <div className="bg-blr-image"
                         style={infoCard?.image && {
                           backgroundImage: 'url(' +
                             (getImageUrl(infoCard.image, 'medium') ||
                               '') + ')',
                         }}/>
                  </div>
                  <div className="content">
                    <div className="inner">
                      <p>{infoCard?.description}</p>
                      <h4><Link to={infoCard?.link}>{infoCard?.title}</Link>
                      </h4>
                      <div className="portfolio-button">
                        <Link to={infoCard?.link}>
                          <a className="rn-btn">{infoCard?.buttonText}</a>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoCardsHero;