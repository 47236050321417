import React, { useEffect, useRef } from 'react';
import Skeleton from 'react-loading-skeleton';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import LazyImage
  from '../../../util/lazyloaders/lazy-image/lazy-image.component';
import { isDevEnv } from '../../../util/util.service';

gsap.registerPlugin(ScrollTrigger);

const IconCardsHero = ({ icons }) => {
  const containerRef = useRef(null);
  const isDebugMode = isDevEnv;

  useEffect(() => {
    if (containerRef.current) {
      const gsapContext = gsap.context(() => {
        const serviceCards = document.querySelectorAll(
          '.icon-cards-hero-container .service-wrapper .service');

        gsap.timeline({
          scrollTrigger: {
            markers: isDebugMode,
            scrub: 1,
            trigger: containerRef.current,
            start: 'top bottom',
            end: 'center center',
            ease: 'linear',
          },
        }).to(serviceCards, { autoAlpha: 1, stagger: 0.3 });
        gsap.set(serviceCards, { autoAlpha: 0 }); // set default
      }, containerRef);

      return () => gsapContext.revert(); // cleanup on destroy
    }
  }, []);

  return (
    <div ref={containerRef} className="icon-cards-hero-container">
      <div
        className="slide slide-style-1 d-flex align-items-center">
        <div className="container position-relative">
          <div className="content">
            <div className="service-wrapper service-white">
              <div className="row">
                {icons?.map((val, i) => (
                  <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                    {val.type === 'full' &&
                      <div className="service service__style--1">
                        <div className="icon mb-3">{val.icon}</div>
                        <div className="content">
                          <h4
                            className="title theme-gradient">{val?.title ||
                            <Skeleton/>}</h4>
                          <p>{val?.description || <Skeleton/>}</p>
                        </div>
                      </div>
                    }
                    {val.type === 'image' &&
                      <div className="service service__style--1 p-0">
                        <LazyImage
                          className="img-cover"
                          src={val.src}
                        />
                      </div>
                    }
                    {val.type === 'title' &&
                      <div className="service service__style--1">
                        <div
                          className="d-flex align-items-center justify-content-center h-100">
                          {val?.title?.length <= 50 ?
                            <h2
                              className="title theme-gradient text-center">{val?.title ||
                              <Skeleton/>}</h2> :
                            <h3
                              className="title theme-gradient text-center">{val?.title ||
                              <Skeleton/>}</h3>
                          }
                        </div>
                      </div>
                    }
                    {val.type === 'text' &&
                      <div className="service service__style--1">
                        <div
                          className="d-flex align-items-center justify-content-center h-100 content">
                          <p
                            className="text-center">{val?.text ||
                            <Skeleton/>}</p>
                        </div>
                      </div>
                    }
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IconCardsHero;