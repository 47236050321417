import React, { useState } from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import Skeleton from 'react-loading-skeleton';

const Counters = ({ data }) => {
  const [didViewCountUp, setDidViewCountUp] = useState(false);

  const onVisibilityChange = (isVisible) => {
    if (isVisible) {
      setDidViewCountUp(true);
    }
  };

  return (
    <div className="row">
      {data?.length > 0 ? data?.map((value, index) => (
          <div className="counterup_style--1 col-lg-4 col-md-4 col-sm-6 col-12"
               key={index}>
            <h5 className="counter">
              <VisibilitySensor onChange={onVisibilityChange}
                                offset={{ top: 10 }} delayedCall>
                <CountUp end={didViewCountUp ? value.countNum : 0}/>
              </VisibilitySensor>
            </h5>
            <p className="description">{value.countTitle}</p>
          </div>
        )) :
        [1, 2, 3].map((value, index) => (
          <div className="counterup_style--1 col-lg-4 col-md-4 col-sm-6 col-12"
               key={index}>
            <h5 className="counter">
              <Skeleton width={80}/>
            </h5>
            <p className="description"><Skeleton count={3}/></p>
          </div>
        ))
      }
    </div>
  );
};

export default Counters;