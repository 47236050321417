import React, { useEffect, useState } from 'react';
import { FiMail, FiMapPin, FiPhone } from 'react-icons/fi';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from 'react-icons/fi';
import PageHelmet from '../../common/page-helmet/page-helmet.component';
import Header from '../../common/header/header.component';
import Footer from '../../common/footer/footer.component';
import Breadcrumb from '../../common/breadcrumb/breadcrumb.component';
import { getContactPage } from '../../../util/api.service';
import Markdown from 'markdown-to-jsx/dist/index';
import Skeleton from 'react-loading-skeleton';
import ContactSection
  from '../../elements/contact-section/contact-section.component';
import { useHistory } from 'react-router-dom';

const Contact = () => {
  const [contact, setContact] = useState(null);
  const history = useHistory();

  useEffect(() => {
    getContactPage(history).then(({ attributes }) => setContact(attributes));
  }, []);

  /*  const defaultProps = {
      center: {
        lat: 59.95,
        lng: 30.33,
      },
      zoom: 11,
    };*/

  return (
    <>
      <PageHelmet pageTitle={contact?.pageTitle}
                  pageDescription={contact?.pageSubTitle}/>
      <Header/>
      <Breadcrumb title={contact?.pageTitle} image={contact?.coverImage}
                  subTitle={contact?.pageSubTitle}/>

      {/* Start Contact Top Area  */}
      <div className="rn-contact-top-area ptb--120 bg_color--5">
        <div className="container">

          <div className="row">
            {/* Start Single Address  */}
            {/*<div className="col-lg-4 col-md-6 col-sm-6 col-12">
              <div className="rn-address">
                <div className="icon">
                  <FiPhone/>
                </div>
                <div className="inner">
                  <h4 className="title">{contact?.phoneBlockTitle ||
                    <Skeleton width="60%"/>}</h4>
                  {contact?.phoneBlockContent ?
                    <Markdown>{contact?.phoneBlockContent}</Markdown> :
                    <Skeleton count={2}/>}
                </div>
              </div>
            </div>*/}
            {/* End Single Address  */}

            {/* Start Single Address  */}
            {/*<div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
              <div className="rn-address">
                <div className="icon">
                  <FiMail/>
                </div>
                <div className="inner">
                  <h4 className="title">{contact?.mailBlockTitle ||
                    <Skeleton width="60%"/>}</h4>
                  {contact?.mailBlockContent ?
                    <Markdown>{contact?.mailBlockContent}</Markdown> :
                    <Skeleton count={2}/>}
                </div>
              </div>
            </div>*/}
            {/* End Single Address  */}

            {/* Start Single Address  */}
            <div
              className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
              <div className="rn-address">
                <div className="icon">
                  <FiMapPin/>
                </div>
                <div className="inner">
                  <h4 className="title">{contact?.phoneBlockTitle ||
                    <Skeleton width="60%"/>}</h4>
                  {contact?.phoneBlockContent ?
                    <Markdown>{contact?.phoneBlockContent}</Markdown> :
                    <Skeleton count={2}/>}
                </div>
              </div>
            </div>
            {/* End Single Address  */}

            {/* Start Single Address  */}
            <div
              className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
              <div className="rn-address">
                <div className="icon">
                  <FiMapPin/>
                </div>
                <div className="inner">
                  <h4 className="title">{contact?.mailBlockTitle ||
                    <Skeleton width="60%"/>}</h4>
                  {contact?.mailBlockContent ?
                    <Markdown>{contact?.mailBlockContent}</Markdown> :
                    <Skeleton count={2}/>}
                </div>
              </div>
            </div>
            {/* End Single Address  */}

            {/* Start Single Address  */}
            <div
              className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
              <div className="rn-address">
                <div className="icon">
                  <FiMapPin/>
                </div>
                <div className="inner">
                  <h4 className="title">{contact?.locationBlockTitle ||
                    <Skeleton width="60%"/>}</h4>
                  {contact?.locationBlockContent ?
                    <Markdown>{contact?.locationBlockContent}</Markdown> :
                    <Skeleton count={2}/>}
                </div>
              </div>
            </div>
            {/* End Single Address  */}

          </div>
        </div>
      </div>
      {/* End Contact Top Area  */}

      {/* Start Contact Page Area  */}
      <div className="rn-contact-page ptb--120 bg_color--1">
        <ContactSection title={contact?.contactFormTitle} description={contact?.contactFormSubtitle} image={contact?.formImage}/>
      </div>
      {/* End Contact Page Area  */}

      {/* Start Contact Map  */}
      {/*<div className="rn-contact-map-area position-relative">
        <div style={{ height: '650px', width: '100%' }}>
          <GoogleMapReact
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
          >
            <AnyReactComponent
              lat={59.955413}
              lng={30.337844}
              text="My Marker"
            />
          </GoogleMapReact>
        </div>
      </div>*/}
      {/* End Contact Map  */}


      {/* Start Brand Area */}
      {/*<div className="rn-brand-area brand-separation bg_color--5 ptb--120">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <BrandTwo/>
            </div>
          </div>
        </div>
      </div>*/}
      {/* End Brand Area */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp/>
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      <Footer/>

    </>
  );
};

export default Contact;