import React from 'react';

const AppLoader = ({isHidden, zIndex = 9999}) => {
  return (
    <div
      className="bg_color--6"
      style={{ position: 'fixed', width: '100vw', height: '100vh', zIndex: zIndex }}
      hidden={isHidden}
    >
      <div style={{
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
      }}>
        <div className="spinner-border color-1" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  );
};

export default AppLoader;
