import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import Skeleton from 'react-loading-skeleton';

const VideoPopup = ({ placeholderImageUrl, channel, videoId, url }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  return (
    <div className="rn-section bg_color--1 line-separator pb--110">
      <div className="container">
        <div className="row sercice-details-content align-items-center">
          <div className="col-lg-12">
            <div className="thumb position-relative">
              {placeholderImageUrl !== null && (placeholderImageUrl ?
                <img className="w-100" src={placeholderImageUrl}
                     alt="Video Placeholder Image"/> :
                <Skeleton width="100%" height="100px"/>)
              }
              <ModalVideo isOpen={isPopupOpen}
                          channel={channel}
                          videoId={videoId}
                          url={url}
                          onClose={() => setIsPopupOpen(false)}/>
              <button className="video-popup position-top-center"
                      onClick={() => setIsPopupOpen(true)}><span
                className="play-icon"></span></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoPopup;