import axios from 'axios';

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const baseGetQuery = (path) => axios.get(`${API_BASE_URL}/api/${path}`).
  then((res) => {
    console.debug(`GET /${path} result:`, res.data);
    return res.data.data;
  }).
  catch((err) => {
    console.debug(`GET /${path} error:`, err.message);
    throw err;
  });

export const handleError = (err, history) => {
  if (err?.response?.status === 404) {
    history.push('/404'); // redirect to 404
  } else {
    history.push('/500'); // redirect to 500
  }
};

export const getCareerPage = (history) => baseGetQuery('career?populate=*').
  catch((err) => handleError(err, history));

export const getAboutUsPage = (history) => baseGetQuery('about-us?populate=*').
  catch((err) => handleError(err, history));

export const getContactPage = (history) => baseGetQuery('contact?populate=*').
  catch((err) => handleError(err, history));

export const getPressPage = (history) => baseGetQuery('press?populate=*').
  catch((err) => handleError(err, history));

export const getBlogPage = (history) => baseGetQuery('blog?populate=*').
  catch((err) => handleError(err, history));

export const getHomePage = (history) => baseGetQuery(
  'home?populate[tiles][populate][0]=image&populate[infoCards][populate][0]=image').
  catch((err) => handleError(err, history));

export const getJobs = (slug) => baseGetQuery(
  'jobs' + (slug ? '?filters[slug][$eq]=' + slug + '&' : '?') + 'populate=*').
  then((data) => slug ? data[0] : data); // if passing slug, there can only be a single entry. Extract it.

export const getMoreJobs = (excludeSlug) => baseGetQuery(
  'jobs' + (excludeSlug ? '?filters[slug][$ne]=' + excludeSlug + '&' : '?') +
  'populate=*');

export const getPressReleases = (slug) => baseGetQuery(
  'press-releases' + (slug ? '?filters[slug][$eq]=' + slug + '&' : '?') +
  'populate=*').
  then((data) => slug ? data[0] : data); // if passing slug, there can only be a single entry. Extract it.

export const getBlogPosts = (slug) => baseGetQuery(
  'blog-posts' + (slug ? '?filters[slug][$eq]=' + slug + '&' : '?') +
  'populate=*').
  then((data) => slug ? data[0] : data); // if passing slug, there can only be a single entry. Extract it.

export const getTeamMembers = () => baseGetQuery('team-members?populate=*');

export const getTestimonials = () => baseGetQuery('testimonials?populate=*');

export const getImprintPage = (history) => baseGetQuery('imprint?populate=*').
  catch((err) => handleError(err, history));

export const getPrivacyPolicyPage = (history) => baseGetQuery(
  'privacy-policy?populate=*').catch((err) => handleError(err, history));

export const createMessage = (form) => axios.post(`${API_BASE_URL}/api/messages`, {data: form} )