import React, { useState } from 'react';
import { IntlProvider } from 'react-intl';
/*import messagesEN from '../assets/data/locales/en.json';
import messagesDE from '../assets/data/locales/de.json';*/

const availableMessages = {
  en: null,
  de: null,
};

const Context = React.createContext({});

const IntlProviderWrapper = ({ children }) => {
  const [locale, setLocale] = useState('en');

  return (
    <Context.Provider
      value={{
        locale: locale,
        switchLanguage: (locale) => {
          localStorage.setItem('locale', locale); // remember for next visit
          setLocale(locale);
        },
      }}
    >
      <IntlProvider
        locale={locale}
        messages={availableMessages[locale]}
        formats={{
          number: {
            priceCurrency: {
              style: 'currency',
              currency: 'EUR',
            },
            priceDecimal: {
              style: 'decimal',
              minimumIntegerDigits: 1,
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            },
            taxPercentage: {
              style: 'percent',
              minimumIntegerDigits: 1,
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            },
          },
          date: {
            full2Digit: {
              year: '2-digit',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
            },
          },
          relative: {
            long: {
              style: 'long',
              numeric: 'auto',
            },
            since: {
              style: 'short',
              numeric: 'always',
            },
          },
        }}
      >
        {children}
      </IntlProvider>
    </Context.Provider>
  );
};

export { IntlProviderWrapper, Context as IntlContext };
