import React, { useEffect, useState } from 'react';
import { getTeamMembers } from '../../../util/api.service';
import { getImageUrl } from '../../../util/util.service';
import Skeleton from 'react-loading-skeleton';
import { FaFacebookF, FaLinkedinIn, FaTwitter } from 'react-icons/fa';

const TeamMembers = () => {
  const [teamMembers, setTeamMembers] = useState(null);

  useEffect(() => {
    getTeamMembers().then((teamMembers) => setTeamMembers(teamMembers));
  }, []);

  return (
    <>
      {teamMembers ? teamMembers.map(({ attributes }) =>
        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
          <div className="team">
            <div className="thumbnail">
              <img className="w-100" src={(attributes?.image &&
                getImageUrl(attributes.image) || '')}
                   alt="Team Member Image"/>
            </div>
            <div className="content">
              <h4 className="title">{attributes?.name || <Skeleton/>}</h4>
              <p className="designation">{attributes?.position ||
                <Skeleton/>}</p>
            </div>
            <ul className="social-icon">
              {attributes?.linkedInUrl &&
                <li><a href={attributes.linkedInUrl}><FaLinkedinIn/></a>
                </li>}
              {attributes?.facebookUrl &&
                <li><a href={attributes.facebookUrl}><FaFacebookF/></a>
                </li>}
              {attributes?.twitterUrl &&
                <li><a href={attributes.twitterUrl}><FaTwitter/></a></li>}
            </ul>
          </div>
        </div>,
      ) : [1, 2, 3].map(() =>
          <div className="col-lg-4 col-md-6 col-sm-6 col-12">
            <div className="team team-style--bottom">
              <Skeleton height={300} width="100%"/>
            </div>
          </div>)
      }
    </>
  );
};

export default TeamMembers;