import React, { useEffect, useState } from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from 'react-icons/fi';
import Slider from 'react-slick';
import { getBlogPage, getBlogPosts } from '../../../util/api.service';
import { slickDot } from '../../../page-demo/script';
import { getImageUrl } from '../../../util/util.service';
import { FormattedDate } from 'react-intl';
import Markdown from 'markdown-to-jsx/dist/index';
import { Link, useHistory } from 'react-router-dom';
import Footer from '../../common/footer/footer.component';
import PageHelmet from '../../common/page-helmet/page-helmet.component';
import Breadcrumb from '../../common/breadcrumb/breadcrumb.component';
import Skeleton from 'react-loading-skeleton';
import Header from '../../common/header/header.component';
import LazyImage
  from '../../../util/lazyloaders/lazy-image/lazy-image.component';

const Blog = () => {
  const [blog, setBlog] = useState(null);
  const [blogPosts, setBlogPosts] = useState(null);
  const history = useHistory();

  // useEffect(() => {
  //   getBlogPage(history).then(({ attributes }) => setBlog(attributes));
  //   getBlogPosts().then((blogPosts) => setBlogPosts(blogPosts.map(({ attributes }) => attributes)));
  // }, []);
  
  useEffect(() => {
    getBlogPage(history).then(({ attributes }) => setBlog(attributes));
    getBlogPosts().then((blogPosts) => {
      // Sort blogPosts by date in descending order
      const sortedBlogPosts = blogPosts
        .map(({ attributes }) => attributes)
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setBlogPosts(sortedBlogPosts);
    });
  }, []);
  

  return (
    <>
      <PageHelmet pageTitle={blog?.title}
                  pageDescription={blog?.subTitle}/>
      <Header/>
      <Breadcrumb title={blog?.title}
                  image={blog?.coverImage}
                  subTitle={blog?.subTitle}
      />

      {/* Start BlogPosts Area */}
      <div class="rn-blog-area ptb--120 bg_color--1">
        <div class="container">
          <div class="row">
            {blogPosts?.map((blogPost, index) => (
              <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={index}>
                <div className="blog blog-style--1">
                  <div className='thumbnail'>
                    <Link to={'/blog/' + blogPost?.slug}>
                      <img src="/assets/images/press/pressrelease-default-bg.jpg" alt="blog post" />
                    </Link>
                  </div>

                  <div className="content">
                    <div className="inner">
                      <p className='text-white'><FormattedDate value={blogPost.createdAt}/>
                      </p>
                      <h4 className='title'><Link
                        to={'/blog/' + blogPost?.slug}>{blogPost?.title}</Link>
                      </h4>
                      <div className="blog-btn">
                        <Link to={'/blog/' + blogPost?.slug}>
                          <a className="rn-btn">Read More</a>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* End BlogPosts Area */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp/>
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      <Footer/>
    </>
  );
};

export default Blog;