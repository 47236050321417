import React, { useEffect, useState } from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from 'react-icons/fi';
import { getAboutUsPage } from '../../../util/api.service';
import Skeleton from 'react-loading-skeleton';
import { Link, useHistory } from 'react-router-dom';
import Testimonials from '../../elements/testimonials/testimonials.component';
import BrandTwo from '../../../elements/BrandTwo';
import Counters from '../../elements/counters/counters.component';
import Footer from '../../common/footer/footer.component';
import PageHelmet from '../../common/page-helmet/page-helmet.component';
import Header from '../../common/header/header.component';
import TeamMembers from '../../elements/team-members/team-members.component';
import Breadcrumb from '../../common/breadcrumb/breadcrumb.component';
import LazyImage
  from '../../../util/lazyloaders/lazy-image/lazy-image.component';
import VideoPopup from '../../elements/video-popup/video-popup.component';
import { getImageUrl } from '../../../util/util.service';

const AboutUs = () => {
  const [aboutUs, setAboutUs] = useState(null);
  const history = useHistory();

  useEffect(() => {
    getAboutUsPage(history).then(({ attributes }) => setAboutUs(attributes));
  }, []);

  return (
    <>
      <PageHelmet pageTitle={aboutUs?.pageTitle}
                  pageDescription={aboutUs?.pageSubTitle}/>
      <Header/>
      <Breadcrumb title={aboutUs?.pageTitle}
                  image={aboutUs?.coverImage}
                  subTitle={aboutUs?.pageSubTitle}
      />

      {/* Start About Area  */}
      <div className="rn-about-area ptb--120 bg_color--1">
        <div className="rn-about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-5">
                <div className="thumbnail">
                  <LazyImage
                    className="w-100"
                    placeholderHeight={400}
                    image={aboutUs?.introBlockImage}
                    size="medium"
                    alt="About Image"
                  />
                </div>
              </div>
              <div className="col-lg-7">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">{aboutUs?.introBlockTitle ||
                      <Skeleton/>}</h2>
                    <p className="description">{aboutUs?.introBlockText ||
                      <Skeleton/>}</p>
                  </div>
                  <div className="row mt--30">
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h3
                          className="title">{aboutUs?.introBlockLeftFactTitle ||
                          <Skeleton/>}</h3>
                        <p>{aboutUs?.introBlockLeftFactText ||
                          <Skeleton count={3}/>}</p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h3
                          className="title">{aboutUs?.introBlockRightFactTitle ||
                          <Skeleton/>}</h3>
                        <p>{aboutUs?.introBlockRightFactText ||
                          <Skeleton count={3}/>}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End About Area  */}


      {(aboutUs?.heroTextHeadline && aboutUs?.heroTextContent &&
          aboutUs?.heroTextImage) &&
        <div className="ptb--120 bg_color--3">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner">
                  <div className="row">
                    <div className="col-lg-8">
                      <h2 className="title text-uppercase"
                          dangerouslySetInnerHTML={{
                            __html: aboutUs.heroTextHeadline.replace(
                              /(<? *script)/gi, 'illegalscript'),
                          }}/>
                    </div>
                  </div>

                  <div
                    className="row sercice-details-content align-items-center">
                    <div className="col-lg-8 col-12 order-1">
                      <div className="details mt_md--30 mt_sm--30">
                        <p>{aboutUs.heroTextContent}</p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-12 order-2">
                      <div className="thumb position-relative">
                        <LazyImage
                          className="w-100"
                          placeholderHeight={400}
                          image={aboutUs?.heroTextImage}
                          size="large"
                          alt="Logo Novocycle"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }

      {/* Start CounterUp Area */}
      <div className="rn-counterup-area ptb--120 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center">
                <h3
                  className="fontWeight500">{aboutUs?.factsBlockTitle ||
                  <Skeleton/>}</h3>
              </div>
            </div>
          </div>
          <Counters data={aboutUs && [
            {
              countNum: aboutUs.factsBlockFirstNumber,
              countTitle: aboutUs.factsBlockFirstText,
            },
            {
              countNum: aboutUs.factsBlockSecondNumber,
              countTitle: aboutUs.factsBlockSecondText,
            },
            {
              countNum: aboutUs.factsBlockThirdNumber,
              countTitle: aboutUs.factsBlockThirdText,
            },
          ]}/>
        </div>
      </div>
      {/* End CounterUp Area */}

      {/* Start Finding Us Area  */}
      <div className="rn-finding-us-area rn-finding-us bg_color--1">
        <div className="inner">
          <div className="content-wrapper">
            <div className="content">
              <h4 className="theme-gradient">{aboutUs?.ctaBlockTitle ||
                <Skeleton width={300}/>}</h4>
              <p>{aboutUs?.ctaBlockText || <Skeleton count={4}/>}</p>
              {aboutUs?.ctaBlockButtonText
                ?
                <Link to={aboutUs.ctaBlockButtonLink || '/'}><a
                  className="rn-btn btn-white">{aboutUs?.ctaBlockButtonText}</a></Link>
                :
                <Skeleton width={200} height={50}/>}

            </div>
          </div>
          <div className="thumbnail">
            <div className="image">
              <LazyImage
                placeholderWidth="70vw"
                placeholderHeight={500}
                image={aboutUs?.ctaBlockImage}
                size="large"
                alt="CTA Image"
              />
            </div>
          </div>
        </div>
      </div>
      {/* End Finding Us Area  */}

      {/* Start Team Area  */}
      <div className="rn-team-area bg_color--1 ptb--120">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="section-title service-style--3 text-center mb--25">
                <h2 className="title">{aboutUs?.memberBlockTitle ||
                  <Skeleton/>}</h2>
                <p>{aboutUs?.memberBlockSubTitle || <Skeleton/>}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <TeamMembers/>
          </div>
          <div className="row pt--110">
            <div className="col-lg-10 mx-auto text-center">
              <h2 className="title text-uppercase">You want to become a part of
                our team?</h2>
              <p>Visit our career page and look if there is a position that
                might be a perfect fit to your skillset!</p>
              <br/>
              <Link to="/career"
                    className="rn-button-style--2 btn-solid">Career</Link>
            </div>
          </div>
        </div>
      </div>
      {/* End Team Area  */}

      {aboutUs?.videoPopupIdOrUrl &&
        <VideoPopup
          placeholderImageUrl={aboutUs?.videoPopupPlaceholderImage
            ? getImageUrl(aboutUs?.videoPopupPlaceholderImage)
            : null}
          url={aboutUs?.videoPopupChannel === 'custom' &&
            aboutUs?.videoPopupIdOrUrl}
          videoId={aboutUs?.videoPopupChannel !== 'custom' &&
            aboutUs?.videoPopupIdOrUrl}
          channel={aboutUs?.videoPopupChannel}
        />
      }

      {/* Start Testimonial Area */}
      {/* <div className="rn-testimonial-area bg_color--5 ptb--120">
        <div className="container">
          <Testimonials/>
        </div>
      </div> */}
      {/* End Testimonial Area */}

      {/* Start Brand Area */}
      {/*<div className="rn-brand-area brand-separation bg_color--5 ptb--120">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <BrandTwo/>
            </div>
          </div>
        </div>
      </div>*/}
      {/* End Brand Area */}

      {/* 
      <div className="rn-about-area ptb--120 bg_color--1">
        <div className="rn-about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-7">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title theme-gradient" style={{lineHeight: 1.4, marginBottom: '2rem'}}>This is a long Title</h2>
                    <p className="description">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent congue scelerisque faucibus. Etiam vitae tristique mauris. Morbi luctus ligula eu elementum malesuada. Praesent pellentesque diam non congue ullamcorper. Nunc auctor, mi id pharetra blandit, ante dolor lacinia turpis, ut euismod nulla dui ac nunc. Curabitur feugiat ullamcorper dolor a feugiat.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="">
                  <LazyImage
                    className="w-100"
                    placeholderHeight={400}
                    // image={aboutUs?.introBlockImage}
                    src="https://sense-and-image.com/wp-content/uploads/elementor/thumbs/Artworks-Marketing-Automation-p5rkmetesmrt7nuq4lttsao3tnwv7e054lvau9rwq8.png"
                    size="medium"
                    alt="About Image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp/>
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      <Footer/>
    </>
  );
};

export default AboutUs;