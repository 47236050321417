import React, { useEffect, useState } from 'react';
import Markdown from 'markdown-to-jsx/dist/index';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from 'react-icons/fi';
import { getPrivacyPolicyPage } from '../../../util/api.service';
import Skeleton from 'react-loading-skeleton';
import Breadcrumb from '../../common/breadcrumb/breadcrumb.component';
import PageHelmet from '../../common/page-helmet/page-helmet.component';
import Header from '../../common/header/header.component';
import Footer from '../../common/footer/footer.component';
import { useHistory } from 'react-router-dom';

const PrivacyPolicy = () => {
  const [privacyPolicy, setPrivacyPolicy] = useState(null);
  const history = useHistory();

  useEffect(() => {
    getPrivacyPolicyPage(history).then(({ attributes }) => setPrivacyPolicy(attributes));
  }, []);

  return (
    <>
      <PageHelmet pageTitle={privacyPolicy?.title}/>
      <Header/>
      <Breadcrumb title={privacyPolicy?.title} image={privacyPolicy?.coverImage} subTitle={null}/>

      <div className="rn-blog-details ptb--120 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="content">
                {privacyPolicy?.content ? <Markdown>{privacyPolicy.content}</Markdown> :
                  <p><Skeleton width="100%" count={9}/></p>}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp/>
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      <Footer/>
    </>
  );
};

export default PrivacyPolicy;
