import React from 'react';
import Skeleton from 'react-loading-skeleton';
import ProgressiveImage from 'react-progressive-graceful-image';
import { getImageUrl } from '../../util.service';

const LazyImage = ({
  image, // Image object
  size, // size code (medium, large, thumbnail, ...)
  src, // fallback if no image was given
  className,
  placeholderClassNames,
  placeholderContainerClassNames,
  alt,
  placeholderHeight,
  placeholderWidth,
  ...props
}) => {
  const imageUrl = getImageUrl(image, size, false);

  return (
    <ProgressiveImage
      src={imageUrl || src}
      retry={{ count: 5, delay: 3, accumulate: 'add' }}
      placeholder=""
      {...props}
    >
      {(src, loading) =>
        loading ? (
          <div className={placeholderContainerClassNames}>
            <Skeleton className={placeholderClassNames} height={placeholderHeight} width={placeholderWidth} />
          </div>
        ) : (
          <img className={className} src={src} alt={image?.fileName || alt} />
        )
      }
    </ProgressiveImage>
  );
};

export default LazyImage;
