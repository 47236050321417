import React from 'react';
import { Link } from 'react-router-dom';
import { getImageUrl } from '../../../util/util.service';
import Skeleton from 'react-loading-skeleton';

const Breadcrumb = ({ title, subTitle, parent, image }) => {
  return (
    <div className={`breadcrumb-area rn-bg-color ptb--120 bg_image ${!getImageUrl(image, 'large', false) ? 'bg_image--1' : ''}`}
         style={image && {
           backgroundImage: 'url(' + (getImageUrl(image, 'large', false) || '') +
             ')',
         }}
         data-black-overlay="7">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="breadcrumb-inner pt--100 rn-page-title">
              <h2 className="title theme-gradient">{title ||
                <Skeleton width="80vw"/>}</h2>
              <p>{subTitle ? subTitle : subTitle === null ? '' : <Skeleton
                width="60vw"/>}</p>
              {parent && !subTitle &&
                <ul className="page-list">
                  <li className="breadcrumb-item"><Link
                    to={'/'}>Home</Link></li>
                  {parent ? <li className="breadcrumb-item">{parent}</li> : ''}
                  <li className="breadcrumb-item active">{title}</li>
                </ul>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Breadcrumb;

