import React from 'react';
import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';

const SocialShare = [
  { Social: <FaFacebookF/>, link: 'https://www.facebook.com/' },
  { Social: <FaLinkedinIn/>, link: 'https://www.linkedin.com/' },
  { Social: <FaInstagram/>, link: 'https://www.instagram.com/' },
  { Social: <FaTwitter/>, link: 'https://twitter.com/' },
];

const Footer = ({
  wrapperBackground,
  footerRightBackground,
}) => {
  return (
    <>
      <footer className="footer-area">
        <div className="footer-wrapper"
             style={wrapperBackground && { background: wrapperBackground }}>
          <div className="row align-items-end row--0">
            <div className="col-lg-6">
              <div className="footer-left">
                <div className="inner">
                  <span>Our Vision. Our dream. Our passion.</span>
                  <h2>FOR A BETTER <br/> TOMORROW</h2>
                  <Link to="/contact">
                    <a className="rn-button-style--2">
                      <span>Contact Us</span>
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="footer-right"
                   style={footerRightBackground &&
                     { background: footerRightBackground }} data-black-overlay="7">
                <div className="row">
                  {/* Start Single Widget  */}
                  <div className="col-lg-6 col-sm-6 col-12">
                    <div className="footer-link">
                      <h4>Quick Link</h4>
                      <ul className="ft-link">
                        <li><Link to="/imprint"><a>Imprint</a></Link></li>
                        <li><Link to="/privacy-policy"><a>Privacy</a></Link></li>
                        <li><Link to="/contact"><a>Let's Talk</a></Link></li>
                      </ul>
                    </div>
                  </div>
                  {/* End Single Widget  */}
                  {/* Start Single Widget  */}
                  <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                    <div className="footer-link">
                      <h4>Say Hello</h4>
                      <ul className="ft-link">
                        <li><a
                          href="mailto:info@novocycle.com">info@novocycle.com</a>
                        </li>
                        <li><a
                          href="mailto:talents@novocycle.com">talents@novocycle.com</a>
                        </li>
                      </ul>

                      {/*<div className="social-share-inner">
                        <ul
                          className="social-share social-style--2 d-flex justify-content-start liststyle mt--15">
                          {SocialShare.map((val, i) => (
                            <li key={i}><a href={`${val.link}`}>{val.Social}</a>
                            </li>
                          ))}
                        </ul>
                      </div>*/}
                    </div>
                  </div>
                  {/* End Single Widget  */}

                  <div className="col-lg-12">
                    <div className="copyright-text">
                      <p>Copyright © {new Date().getFullYear()} Novocycle
                        Technologies. All Rights Reserved.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
    ;
};

export default Footer;