import React, { useEffect, useState } from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from 'react-icons/fi';
import Slider from 'react-slick';
import { getPressPage, getPressReleases } from '../../../util/api.service';
import { slickDot } from '../../../page-demo/script';
import { getImageUrl } from '../../../util/util.service';
import { FormattedDate } from 'react-intl';
import Markdown from 'markdown-to-jsx/dist/index';
import { Link, useHistory } from 'react-router-dom';
import Footer from '../../common/footer/footer.component';
import PageHelmet from '../../common/page-helmet/page-helmet.component';
import Breadcrumb from '../../common/breadcrumb/breadcrumb.component';
import Skeleton from 'react-loading-skeleton';
import Header from '../../common/header/header.component';
import LazyImage
  from '../../../util/lazyloaders/lazy-image/lazy-image.component';

const Press = () => {
  const [press, setPress] = useState(null);
  const [pressReleases, setPressReleases] = useState(null);
  const history = useHistory();

  // useEffect(() => {
  //   getPressPage(history).then(({ attributes }) => setPress(attributes));
  //   getPressReleases().then((pressReleases) => setPressReleases(pressReleases.map(({ attributes }) => attributes)));
  // }, []);

  useEffect(() => {
    getPressPage(history).then(({ attributes }) => setPress(attributes));
    getPressReleases().then((pressReleases) => {
      // Sort pressReleases by date in descending order
      const sortedPressReleases = pressReleases
        .map(({ attributes }) => attributes)
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setPressReleases(sortedPressReleases);
    });
  }, []);

  return (
    <>
      <PageHelmet pageTitle={press?.title}
                  pageDescription={press?.subTitle}/>
      <Header/>
      <Breadcrumb title={press?.title}
                  image={press?.coverImage}
                  subTitle={press?.subTitle}
      />

      {/* Start PressReleases Area */}
      <div class="rn-blog-area ptb--120 bg_color--1">
        <div class="container">
          <div class="row">
            {pressReleases?.map((pressRelease, index) => (
              <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={index}>
                <div className="blog blog-style--1">
                  <div className='thumbnail'>
                    <Link to={'/press/' + pressRelease?.slug}>
                      <img src="/assets/images/press/pressrelease-default-bg.jpg" alt="press release" />
                    </Link>
                  </div>

                  <div className="content">
                    <div className="inner">
                      <p className='text-white'><FormattedDate value={pressRelease.createdAt}/>
                      </p>
                      <h4 className='title'><Link
                        to={'/press/' + pressRelease?.slug}>{pressRelease?.title}</Link>
                      </h4>
                      <div className="blog-btn">
                        <Link to={'/press/' + pressRelease?.slug}>
                          <a className="rn-btn">Read More</a>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* End PressReleases Area */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp/>
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      <Footer/>
    </>
  );
};

export default Press;