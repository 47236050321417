import React, { useEffect, useState } from 'react';
import BatteryHero from '../../elements/battery-hero/battery-hero.component';
import PageHelmet from '../../common/page-helmet/page-helmet.component';
import Header from '../../common/header/header.component';
import ScrollToTop from 'react-scroll-up';
import * as ReactIcons from 'react-icons/fi';
import Footer from '../../common/footer/footer.component';
import { getHomePage } from '../../../util/api.service';
import ParallaxGradientHero
  from '../../elements/parallax-gradient-hero/parallax-gradient-hero.component';
import GradientMaskedTextHero
  from '../../elements/gradient-masked-text-hero/gradient-masked-text-hero.component';
import IconCardsHero
  from '../../elements/icon-cards-hero/icon-cards-hero.component';
import { getImageUrl } from '../../../util/util.service';
import InfoCardsHero
  from '../../elements/info-cards-hero/info-cards-hero.component';
import { useHistory } from 'react-router-dom';

const IconByName = ({ iconName, ...props }) => {
  const Icon = ReactIcons[iconName];

  if (!Icon) return null;

  return <Icon {...props}/>;
};

const Home = () => {
  const [home, setHome] = useState(null);
  const history = useHistory();

  useEffect(() => {
    getHomePage(history).then(({ attributes }) => {
      setHome(attributes);
    });

    // set bg color of the body. Fixes the white corners problem
    document.body.classList.add('bg_color--6');

    return () => {
      document.body.classList.remove('bg_color--6'); // remove class again when changing page
    }
  }, []);

  return (
    <div className="home-component bg_color--6">
      <PageHelmet pageTitle="Home"/>
      <Header/>

      <svg width="0" height="0">
        <linearGradient id="novocycle-gradient" x1="100%" y1="100%" x2="0%"
                        y2="0%">
          <stop stopColor="#147d6a" offset="0%"/>
          <stop stopColor="#7cb7ac" offset="100%"/>
        </linearGradient>
      </svg>

      <div className="light-header">
      <BatteryHero/>

        {(home?.parallaxGradientHeroTitle ||
            home?.parallaxGradientHeroSubTitle) &&
          <ParallaxGradientHero
            title={home.parallaxGradientHeroTitle}
            subTitle={home.parallaxGradientHeroSubTitle}
          />
        }

        {(home?.heroTextHeadline && home?.heroTextContent) &&
          <div
            className="hero-text-block rn-columns-area ptb--160 bg_color--6">
            <div className="container">
              <div className="row">
                <div className="col-lg-10 mx-auto">
                  <h2
                    className="hero-text-block--headline text-uppercase text-white"
                    dangerouslySetInnerHTML={{
                      __html: home.heroTextHeadline.replace(/(<? *script)/gi,
                        'illegalscript'),
                    }}/>

                  <p className="text-white hero-text-block--text">
                    {home.heroTextContent}
                  </p>
                </div>
              </div>
            </div>
          </div>
        }

        <div
          className="novo-home-text-block rn-columns-area ptb--120 bg_color--6 d-none">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 mx-auto">
                <div className="single-column">
                  <h2 className="text-uppercase text-white"
                      style={{ fontSize: '55px' }}>The transition towards
                    electric mobility is not 100% carbon neutral and it is
                    time
                    that we all accept this fact.</h2>
                </div>
              </div>
              <div className="col-lg-10 mx-auto">
                <div className="single-column">
                  <p className="text-white" style={{
                    fontSize: '25px',
                    lineHeight: '32px',
                    columnCount: '2',
                    columnGap: '2',
                  }}>Unfortunately, electric vehicles were presented as 100%
                    carbon neutral in the last years. In fact, this is not
                    true.
                    If we look at the entire supply chain, electric vehicles
                    emit around 30 tons of CO2 eq. during their life time. In
                    order to get electric vehicles more sustainable, recycling
                    and reusing the most important components becomes crucial.
                    The biggest impact is done by the mining of the raw
                    materials and the production of Lithium-Ion Batteries.
                    Around 140 kg of CO2 equivalents per kWh are emitted
                    during
                    the production of Lithium-Ion Batteries. If we think about
                    a
                    Tesla Model S with a 95kWh battery, this would lead to an
                    emission of 13.3 tons of CO2 eq. Adding the in majority
                    still fossil based energy sources for the electricity to
                    charge the batteries, the 30 tons CO2 eq. per Lifetime are
                    easy to understand. </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {home?.tiles?.length > 0 &&
          <IconCardsHero icons={home?.tiles.map((tile) => {
            // eslint-disable-next-line default-case
            switch (tile.__component) {
              case 'tiles.full':
                return {
                  type: 'full',
                  icon: <IconByName iconName={tile.icon} size={54}
                                    style={{ stroke: 'url(#novocycle-gradient)' }}/>,
                  title: tile.title,
                  description: tile.description,
                };
              case 'tiles.image-tile':
                return {
                  type: 'image',
                  src: getImageUrl(tile.image, null, true),
                };
              case 'tiles.title-tile':
                return {
                  type: 'title',
                  title: tile.title,
                };
              case 'tiles.text-tile':
                return {
                  type: 'text',
                  text: tile.text,
                };
            }
          })}/>
        }

        {home?.infoCards?.length > 0 &&
          <InfoCardsHero infoCards={home?.infoCards}/>
        }


        {home?.gradientMaskedTextHeroText &&
          <GradientMaskedTextHero
            text={home?.gradientMaskedTextHeroText}
          />
        }

        {/*<div ref={afterHeroRef} className="after-hero">
           Start About Area
          <div className="about-area about-position-top pb--120">
            <div className="about-wrapper">
              <div className="container">
                <div className="row row--35 align-items-center">

                  <div className="col-lg-5 col-md-12">
                    <div className="thumbnail">
                      <LazyImage
                        className="w-100"
                        placeholderHeight={500}
                        image={home?.firstSectionCover}
                        size="medium"
                        alt="About Image"
                      />
                    </div>
                  </div>

                  <div className="col-lg-7 col-md-12 offset-2 col-8">
                    <div className="about-inner inner text-center">
                      <div className="section-title">
                        <h2
                          className="title theme-gradient">{home?.firstSectionTitle ||
                          <Skeleton/>}</h2>
                        <p
                          className="description">{home?.firstSectionSubTitle ||
                          <Skeleton/>}</p>
                      </div>
                      <div className="row mt--30 mt_sm--10">
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                          <div className="about-us-list">
                            <h3
                              className="title">{home?.firstSectionLeftBlockTitle ||
                              <Skeleton/>}</h3>
                            <p>{home?.firstSectionLeftBlockText ||
                              <Skeleton/>}</p>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                          <div className="about-us-list">
                            <h3
                              className="title">{home?.firstSectionRightBlockTitle ||
                              <Skeleton/>}</h3>
                            <p>{home?.firstSectionRightBlockText ||
                              <Skeleton/>}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
           End About Area

          <div className="service-area ptb--80  bg_image bg_image--3">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-12">
                <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                  <h2 className="title">Services</h2>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In elementum.</p>
                  <div className="service-btn">
                    <a className="btn-transparent rn-btn-dark" href="/service"><span className="text">Request Custom Service</span></a>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-12 mt_md--50">
                <div className="row service-one-wrapper">
                  {ServiceList2.map( (val , i) => (
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                      <a href="/service-details">
                        <div className="service service__style--2">
                          <div className="icon">
                            {val.icon}
                          </div>
                          <div className="content">
                            <h3 className="title">{val.title}</h3>
                            <p>{val.description}</p>
                          </div>
                        </div>
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

           Start Portfolio Area
          <div className="portfolio-area ptb--120 bg_color--1">
          <div className="portfolio-sacousel-inner mb--55">
            <Portfolio/>
          </div>
        </div>
           End Portfolio Area


           Start CounterUp Area
          <div className="rn-counterup-area pt--25 pb--110 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <h3 className="fontWeight500">Our Fun Facts</h3>
                </div>
              </div>
            </div>
            <Counters/>
          </div>
        </div>

           End CounterUp Area

           Start Testimonial Area
          <div className="rn-testimonial-area ptb--120">
            <div className="container">
              <Testimonials/>
            </div>
          </div>
           End Testimonial Area

           Start Blog Area
          <div className="rn-blog-area pt--120 bg_color--1 mb-dec--30">
          <div className="container">
            <div className="row align-items-end">
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="section-title text-left">
                  <h2>Latest News</h2>
                  <p>There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered alteration.</p>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <div
                  className="blog-btn text-left text-lg-right mt_sm--10 mt_md--10">
                  <a className="btn-transparent rn-btn-dark" href="/blog"><span
                    className="text">View All News</span></a>
                </div>
              </div>
            </div>
            <div className="row mt--60 mt_sm--40">
              {PostList.map((value, i) => (
                <div className="col-lg-4 col-md-6 col-12" key={i}>
                  <div className="blog blog-style--1">
                    <div className="thumbnail">
                      <a href="/blog-details">
                        <img className="w-100"
                             src={`/assets/images/blog/blog-${value.images}.jpg`}
                             alt="Blog Images"/>
                      </a>
                    </div>
                    <div className="content">
                      <p className="blogtype">{value.category}</p>
                      <h4 className="title"><a
                        href="/blog-details">{value.title}</a></h4>
                      <div className="blog-btn">
                        <a className="rn-btn text-white" href="/blog-details">Read
                          More</a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
           End Blog Area


           Start Brand Area
          <div className="rn-brand-area brand-separation bg_color--5 pb--120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <BrandTwo/>
              </div>
            </div>
          </div>
        </div>
           End Brand Area
        </div>*/}
      </div>

      <div className="bg_color--1 ptb--160">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 mx-auto"> {/*  temporarily. origin just col-lg-12 */}
              <h2 className="text-center w-100 mb-5"
                  style={{ color: '#00C29F' }}>OUR PARTNER NETWORK</h2>
              <ul className="brand-list branstyle--1">
                <li style={{ flexBasis: '50%;' }}>
                  <a href="https://www.eu-startups.com/" target="_blank"
                     rel="noopener noreferrer">
                    <img src="/assets/images/brand/EU-Startups-Logo.png"
                         alt="EU-Startups-Logo"/>
                  </a>
                </li>
                <li style={{ flexBasis: '50%;' }}>
                  <a href="https://ibesalliance.org/" target="_blank"
                     rel="noopener noreferrer">
                    <img src="/assets/images/brand/IBESA_Logo.png"
                         alt="IBESA_Logo"/>
                  </a>
                </li>
                {/* 
                <li>
                  <a href="https://bantgo.ae/" target="_blank"
                     rel="noopener noreferrer">
                    <img src="/assets/images/brand/partner-logo-BANTgo-impact2earn.png"
                         alt="BANTgo-impact2earn"/>
                  </a>
                </li>
                <li>
                  <a href="https://sulmi.ai/" target="_blank"
                     rel="noopener noreferrer">
                    <img src="/assets/images/brand/partner-logo-sulmi.png"
                         alt="sulmi_Logo"/>
                  </a>
                </li>
                <li>
                  <a href="https://www.roqumobility.com/" target="_blank"
                     rel="noopener noreferrer">
                    <img src="/assets/images/brand/partner-logo-roqu.png"
                         alt="roqumobility_Logo"/>
                  </a>
                </li>
                <li>
                  <a href="https://www.sabic.com/en" target="_blank"
                     rel="noopener noreferrer">
                    <img src="/assets/images/brand/partner-logo-sabic.png"
                         alt="sabic_Logo"/>
                  </a>
                </li>
                <li>
                  <a href="https://saharobotik.com/" target="_blank"
                     rel="noopener noreferrer">
                    <img src="/assets/images/brand/partner-logo-saha.png"
                         alt="saharobotik_Logo"/>
                  </a>
                </li>
                <li>
                  <a href="https://metltd.net/en" target="_blank"
                     rel="noopener noreferrer">
                    <img src="/assets/images/brand/partner-logo-met.png"
                         alt="met_Logo"/>
                  </a>
                </li>
                */}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <ReactIcons.FiChevronUp/>
        </ScrollToTop>
      </div>

      <Footer wrapperBackground="#fff"/>
    </div>
  );
};

export default Home;