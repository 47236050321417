import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import { FiChevronDown } from 'react-icons/all';
import { isDevEnv } from '../../../util/util.service';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

const BatteryHero = ({
  frameCount = 559,
  frameLoadBatchSize = 20,
  mobileBreakpoint = 599, // in px
  initialScrollToLabel = true,
}) => {
  const picturesRef = useRef([]);
  const animationRef = useRef({ frame: 0 });
  const lazyLoadRef = useRef(null);
  const containerRef = useRef(null);
  const canvasRef = useRef(null);
  const contextRef = useRef(null);
  // idea: use 9:16 frames if mobile device
  const isMobileDevice = window.innerWidth <= mobileBreakpoint &&
    window.innerWidth < window.innerHeight;
  const isDebugMode = isDevEnv;
  const frameCountByDevice = isMobileDevice ? 530 : frameCount;

  // builds an url to the frame by index
  const getCurrentFrameLink = (index, isWebp = false) => {
    const format = isMobileDevice ? '9-16' : '16-9';
    const type = isWebp ? 'webp' : 'jpg';

    return `/assets/images/battery-hero/${format}/${type}/frame-${index}.${type}`;
  };

  // calculates the "to" and "from" breakpoints for lazy loading
  const calculateLazyLoadSteps = (totalFrames) => [
    ...new Array(totalFrames - 1).keys()].reduce(
    (prev, curr) => {
      const prevBatchTo = prev[prev.length - 1].to;
      const currBatchTo = prevBatchTo + frameLoadBatchSize > totalFrames
        ? totalFrames // case if next batch would overflow
        : prevBatchTo + frameLoadBatchSize;

      return prevBatchTo === curr + 1
        ? [...prev, { from: prevBatchTo, to: currBatchTo, isLoaded: false }]
        : prev;
    }, [{ from: 0, to: frameLoadBatchSize, isLoaded: false }],
  );

  // responsible for displaying a frame + preloading frame batches
  const renderFrame = (index) => {
    const picture = picturesRef.current[index];
    const img = picture.querySelector('img');

    const canvasWidth = canvasRef?.current?.width || 1; // use 1 to avoid unexpected behaviour
    const canvasHeight = canvasRef?.current?.height || 1;

    const imgWidth = img.naturalWidth || 1;
    const imgHeight = img.naturalHeight || 1;
    const imgAspectRatio = imgWidth / imgHeight; // ... and to avoid a div by 0 error

    const width = canvasHeight * imgAspectRatio;
    const x = (canvasWidth - width) / 2;

    contextRef.current.clearRect(0, 0, canvasWidth, canvasHeight);
    contextRef.current.drawImage(img, x, 0, width, canvasHeight);
  };

  // watches the currentStep and pre-loads frames if needed
  const preLoadFrames = (index) => {
    const currentStep = lazyLoadRef.current.find(
      ({ from, to }) => from <= index && index < to);

    if (currentStep && !currentStep.isLoaded) {
      if (isDebugMode) {
        console.log(
          `Triggered lazy load of frames ${currentStep.to}-${currentStep.to +
          frameLoadBatchSize}`);
      }
      currentStep.isLoaded = true;

      picturesRef.current.slice(currentStep.to,
        currentStep.to + frameLoadBatchSize).
        forEach((picture) => lazyLoad(picture));
    }
  };

  // swaps the data-srcset with srcset and inits the download with that
  const lazyLoad = (picture) => {
    const img = picture.querySelector('img');
    const sources = picture.querySelectorAll('source');

    sources.forEach((source) => {
      source.srcset = source.dataset.srcset;
      source.removeAttribute('data-srcset');
    });

    img.src = img.dataset.src;
    img.removeAttribute('data-src');
  };

  // creates a picture element in the dom and appends it to picturesRef
  // also creates a webp version with jpg fallback
  const createFrame = (index, lazyLoad = true) => {
    const picture = document.createElement('picture');
    const webpSource = document.createElement('source');
    const img = document.createElement('img');

    // adds the frame link to dataset if lazyLoad = true
    if (lazyLoad) {
      webpSource.dataset.srcset = getCurrentFrameLink(index, true);
      img.dataset.src = getCurrentFrameLink(index, false); // fallback for old browsers
    } else {
      webpSource.srcset = getCurrentFrameLink(index, true);
      img.src = getCurrentFrameLink(index, false); // fallback for old browsers
    }

    webpSource.type = 'image/webp';

    picture.append(webpSource);
    picture.append(img);

    canvasRef.current.append(picture);
    picturesRef.current.push(picture);
  };

  // we will use the first second, while user is reading the intro title
  // to preload 3 more blocks of frames. We will have 4*frameLoadBatchSize
  // frames available then, that should be enough. frameLoadBatchSize should
  // be kept low, to avoid initial freeze (e.g. 25 or so)
  useEffect(() => {
    // first block will be loaded normally, on page load
    // second + third blocks will be lazy loaded after page load
    const handler = () => {
      // frames 0 - <frameLoadBatchSize>
      // already loaded in line 146, just mark as loaded
      preLoadFrames(0);
      lazyLoadRef.current[0].isLoaded = true;

      // pre-load frames <frameLoadBatchSize> - <frameLoadBatchSize * 2>
      preLoadFrames(frameLoadBatchSize);
      lazyLoadRef.current[1].isLoaded = true; // mark as (lazy)loaded

      // pre-load frames <frameLoadBatchSize * 2> - <frameLoadBatchSize * 3>
      preLoadFrames(frameLoadBatchSize * 2);
      lazyLoadRef.current[2].isLoaded = true; // mark as (lazy)loaded

      // pre-load frames <frameLoadBatchSize * 3> - <frameLoadBatchSize * 4>
      preLoadFrames(frameLoadBatchSize * 3);
      lazyLoadRef.current[3].isLoaded = true; // mark as (lazy)loaded
    };

    // scroll to "scroll down" position
    if (initialScrollToLabel) {
      setTimeout(() =>
        gsap.to(window, {
          duration: '0.5',
          scrollTo: {
            y: containerRef.current.offsetHeight * 0.03225,
            autoKill: false,
          },
          ease: 'linear',
        }), 3000);
    }

    if (document.readyState === 'complete') {
      // case when returning back to this component
      setTimeout(handler, 1000);
    } else {
      // case when loading the whole app
      window.addEventListener('load', handler);
      return () => document.removeEventListener('load', handler);
    }
  }, []);

  useEffect(() => {
    lazyLoadRef.current = calculateLazyLoadSteps(frameCountByDevice);
    contextRef.current = canvasRef.current.getContext('2d');

    // canvas size is always full HD
    contextRef.current.width = isMobileDevice ? 1080 : 1920;
    contextRef.current.height = isMobileDevice ? 1920 : 1080;

    canvasRef.current.width = contextRef.current.width;
    canvasRef.current.height = contextRef.current.height;

    // prepare images with picture tags for webp compatibility
    // preload the first images without lazy loading
    for (let i = 1; i <= frameCountByDevice; i++) {
      createFrame(i, i > frameLoadBatchSize);
    }

    // init the gsap context where all magic happens
    const gsapContext = gsap.context(() => {
      gsap.to(animationRef.current, {
        frame: frameCountByDevice - 1,
        snap: 'frame',
        scrollTrigger: {
          scrub: 1,
          trigger: containerRef.current,
          start: 'top top',
          end: 'bottom center',
        },
        onUpdate: () => {
          const frameIndex = animationRef.current.frame;
          if (isDebugMode) {
            console.log('frameIndex:', frameIndex);
          }
          renderFrame(frameIndex);
          if (frameIndex > frameLoadBatchSize) {
            preLoadFrames(frameIndex + frameLoadBatchSize * 3);
          }
        }, // use animation onUpdate instead of scrollTrigger's onUpdate
      });

      gsap.timeline({
        scrollTrigger: {
          trigger: containerRef.current,
          markers: isDebugMode,
          scrub: 1,
          start: '0%',
          end: '3.5%',
          ease: 'linear',
        },
      }).set('#initial-title', { autoAlpha: 1 }) // set default
        .to('#initial-title', { top: '110%', autoAlpha: 0 });

      gsap.timeline({
        scrollTrigger: {
          trigger: containerRef.current,
          markers: isDebugMode,
          scrub: 1,
          start: '2.525%',
          end: '3.525%',
        },
        ease: 'linear',
      }).
        to('#label-scroll-down', { top: '80%', autoAlpha: 1 }).
        to('#label-scroll-down', { top: '95%', autoAlpha: 0, duration: 0.5 },
          '+=20%');
      gsap.set('#label-scroll-down', { top: '65%', visibility: 'hidden' }); // set default

      gsap.timeline({
        scrollTrigger: {
          trigger: containerRef.current,
          markers: isDebugMode,
          scrub: 1,
          start: '5.125%',
          end: '8.25%',
          ease: 'linear',
        },
      }).
        to('#label-1', { top: '80%', autoAlpha: 1 }).
        to('#label-1', { top: '95%', autoAlpha: 0 }, '+=20%');
      gsap.set('#label-1', { top: '65%', visibility: 'hidden' }); // set default

      gsap.timeline({
        scrollTrigger: {
          trigger: containerRef.current,
          markers: isDebugMode,
          scrub: 1,
          start: '12.5%',
          end: '23.875%',
          ease: 'linear',
        },
      }).
        to('#label-2', { top: '30%', autoAlpha: 1 }).
        to('#label-2', { top: '15%', autoAlpha: 0 }, '+=20%');
      gsap.set('#label-2', { visibility: 'hidden' }); // set default

      gsap.timeline({
        scrollTrigger: {
          trigger: containerRef.current,
          markers: isDebugMode,
          scrub: 1,
          start: '21.875%',
          end: '38.125%',
          ease: 'linear',
        },
      }).
        to('#label-3', { top: '80%', autoAlpha: 1 }).
        to('#label-3', { top: '95%', autoAlpha: 0 }, '+=20%');
      gsap.set('#label-3', { visibility: 'hidden', top: '70%' }); // set default

      gsap.timeline({
        scrollTrigger: {
          trigger: containerRef.current,
          markers: isDebugMode,
          scrub: 1,
          start: '36.5%',
          end: '67.375%',
          ease: 'linear',
        },
      }).
        to('#label-4', { top: '75%', autoAlpha: 1 }).
        to('#label-4', { top: '95%', autoAlpha: 0 }, '+=20%');
      gsap.set('#label-4', { visibility: 'hidden', top: '65%' }); // set default

      gsap.timeline({
        scrollTrigger: {
          trigger: containerRef.current,
          markers: isDebugMode,
          scrub: 1,
          start: '70.625%',
          end: '95%',
          ease: 'linear',
        },
      }).
        to('#label-5', { top: '75%', autoAlpha: 1 }).
        to('#label-5', { top: '95%', autoAlpha: 0 }, '+=20%');
      gsap.set('#label-5', { visibility: 'hidden', top: '65%' }); // set default

      gsap.timeline({
        scrollTrigger: {
          trigger: containerRef.current,
          markers: isDebugMode,
          scrub: 1,
          start: '87.5%',
          end: '97.5%',
          ease: 'linear',
        },
      }).
        to('#rootCanvas', { autoAlpha: 0 });
    }, containerRef);

    return () => gsapContext.revert(); // cleanup on destroy
  }, []);

  return (
    <div ref={containerRef} className="battery-hero-container"
         style={{ height: '8000px' }}>
      <div className="content">
        <h1 id="initial-title" className="theme-gradient">Ever wondered, how a
          Car Battery was recycled?</h1>
        <h2 id="label-scroll-down" className="theme-gradient">Scroll down for
          dismantling <br/><FiChevronDown className="theme-color" size={40}/>
        </h2>
        <h2 id="label-1" className="theme-gradient">Lithium-ion batteries are
          the main energy source for electric mobility. But they are not 100%
          carbon neutral.</h2>
        <h2 id="label-2" className="theme-gradient">In fact, 140 kg/kWh are
          emitted during the production - that is not sufficient enough. That's
          why recycling is crucial.</h2>
        <h2 id="label-3" className="theme-gradient">We developed a new recycling
          process, which enables our customers to recover the valuable
          materials.</h2>
        <h2 id="label-4" className="theme-gradient">With a high purity, so that
          they can be re-used in the production of new Lithium-ion
          batteries.</h2>
        <h2 id="label-5" className="theme-gradient">That's how we're aiming to
          close the loop - to make the future greener.</h2>
      </div>
      <canvas id="rootCanvas" ref={canvasRef}/>
    </div>
  );
};

export default BatteryHero;